import CompanyServices from '../services/company-services.service';
import Services from '../views/Services.vue';

export default (context, router) => {
    return {
        accessCheck: async () => true,
        scope: 'company',
        order: 26,
        component: Services,
        route: {
            name: 'services.crud.services',
            path: '/company/services',
            meta: {
                label: 'services.grid-title',
                service: new CompanyServices(),
            },
        },
    };
};
