import axios from '@/config/app';
import ResourceService from '@/services/resource.service';

export default class CompanyServicesService extends ResourceService {
    async getAll(filters = {}) {
        return axios.post('api/v1/client-services/list', filters);
    }

    async update(id, data) {
        return axios.put(`/api/v1/client_services/${id}`, data);
    }

    async getItem(id) {
        return axios.get(`/api/v1/client_services/${id}`);
    }
}
