export const ModuleConfig = {
    routerPrefix: 'settings',
    loadOrder: 25,
    moduleName: 'Services',
};

export function init(context, router) {
    context.addCompanySection(require('./sections/services').default(context, router));

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    context.addRoute({
        path: '/company/services/view/:id',
        name: 'company.services.view',
        component: () => import(/* webpackChunkName: "service" */ './views/actions/View.vue'),
        meta: {
            auth: true,
        },
    });

    context.addRoute({
        path: '/client-services/:id',
        name: 'client-services.list',
        component: () => import('./views/clientServices/ClientServices.vue'),
        meta: {
            auth: true,
            checkPermission: () => true,
        },
    });

    return context;
}
