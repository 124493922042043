<template>
    <div class="documents-page">
        <div v-if="documents.length === 0" class="no-data">
            <h1>{{ $t('company.documents.current.no_data_title') }}</h1>
            <p>{{ $t('company.documents.current.no_data_message') }}</p>
        </div>

        <div v-else>
            <h1 v-if="filter === 'current'">{{ $t('company.documents.approved.current_title') }}</h1>
            <h1 v-if="filter === 'archive'">{{ $t('company.documents.approved.archive_title') }}</h1>

            <div class="filter">
                <button
                    :class="{ active: filter === 'current' }"
                    class="at-btn at-btn--primary at-btn--large action-button"
                    @click="currentScope"
                >
                    {{ $t('company.documents.current.buttons.current') }}
                </button>
                <button
                    :class="{ active: filter === 'archive' }"
                    class="at-btn at-btn--primary at-btn--large action-button"
                    @click="archivedScope"
                >
                    {{ $t('company.documents.current.buttons.archive') }}
                </button>
            </div>

            <ul class="document-list">
                <li v-for="document in documents" :key="document.attributes.id" class="document-item">
                    <div class="document-info">
                        <h3>{{ document.attributes.name }} (v{{ document.attributes.version }})</h3>
                        <p>
                            <strong>{{ $t('company.documents.current.fields.company') }}</strong>
                            {{ document.attributes.company.attributes.name }}
                        </p>

                        <div v-if="document.attributes.partner_companies.length">
                            <p v-for="partner in document.attributes.partner_companies" :key="partner.id">
                                <strong>{{ $t('company.documents.current.fields.partner_company') }}</strong>
                                {{ partner.attributes.company_name }}
                                - <strong>{{ $t('company.documents.current.fields.partner_company_approved') }}</strong>
                            </p>
                        </div>
                        <div v-else>
                            <p>{{ $t('company.documents.current.fields.no_partner_company') }}</p>
                        </div>
                    </div>

                    <button
                        class="view-button at-btn at-btn--primary at-btn--large action-button"
                        @click="$router.push({ path: `view/${document.attributes.id}` })"
                    >
                        {{ $t('company.documents.current.buttons.view') }}
                    </button>
                </li>
            </ul>
        </div>
        <div v-if="documents.length > 0" class="pagination">
            <at-pagination
                :total="totalItems"
                :current="page"
                :page-size="itemsPerPage"
                class="crud__pagination"
                @page-change="onPageChange"
            >
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Service from '../../document/services/document.service';

    export default {
        data() {
            return {
                filter: 'current',
                archived: false,
                documents: [],
                service: new Service(),

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,

                queryParams: {
                    page: this.$route.query.page,
                },
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        created() {
            this.fetchDocuments();
        },
        methods: {
            viewDocument(documentId) {
                alert(`Viewing details for document ID: ${documentId}`);
            },
            async fetchDocuments() {
                let find_params = {
                    ...(this.archived !== '' && { archived: this.archived }),
                    filter: 'approved',
                    page: this.queryParams.page,
                };
                try {
                    const res = await this.service.getAll(find_params);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.documents = data;
                } catch (error) {
                    console.log('Error documents request', error);
                }
            },
            archivedScope() {
                this.filter = 'archive';
                this.archived = true;
            },
            currentScope() {
                this.filter = 'current';
                this.archived = false;
            },

            async onPageChange(page) {
                await this.handlePageChange(page);
            },

            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchDocuments();
            },
        },
        watch: {
            filter(_value) {
                this.fetchDocuments();
            },
        },
    };
</script>

<style scoped lang="scss">
    .documents-page {
        max-width: 800px;
        margin: 50px auto;
        padding: 20px;
        background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
        color: #333;

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
        }
    }
    .active {
        color: #ffa500;

        .theme-dark & {
            color: #c4c4cf;
            border-color: #c4c4cf;
        }
    }

    .pagination {
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
    }

    .no-data {
        text-align: center;
        margin-top: 50px;

        h1 {
            font-size: 24px;
            color: #555;

            .theme-dark & {
                color: #ffa500;
            }
        }

        p {
            font-size: 16px;
            color: #777;

            .theme-dark & {
                color: #c4c4cf;
            }
        }
    }

    h1 {
        text-align: center;
        font-size: 2em;
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .filter {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
    }

    .search-bar {
        display: block;
        width: 100%;
        padding: 12px 16px;
        font-size: 14px;
        border: 1px solid #ddd;
        border-radius: 8px;
        transition: border-color 0.3s, box-shadow 0.3s;
        margin-bottom: 10px;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .document-list {
        list-style: none;
        padding: 0;

        .document-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            margin-bottom: 15px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s ease;

            &:hover {
                transform: translateY(-4px);
                box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
            }

            .theme-dark & {
                background-color: #333;
                border-color: #555;
                color: #ffa500;
            }

            .document-info {
                flex: 1;

                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                    color: #333;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: #555;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }

                strong {
                    color: #333;

                    .theme-dark & {
                        color: #ffa500;
                    }
                }
            }
        }
    }
</style>
