<template>
    <div class="services-container">
        <div class="buttons">
            <button
                class="toggle-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                :class="{ active: viewType === 'ordered' }"
                @click="toggleServiceScope('ordered')"
            >
                {{ $t('services.buttons.ordered') }}
            </button>
            <button
                class="toggle-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                :class="{ active: viewType === 'sold' }"
                @click="toggleServiceScope('sold')"
            >
                {{ $t('services.buttons.sold') }}
            </button>
        </div>

        <h2 v-if="viewType === 'ordered'">{{ $t('services.header') }}</h2>
        <h2 v-if="viewType === 'sold'">{{ $t('services.soldHeader') }}</h2>

        <div class="buttons">
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                :class="{ active: archived == false }"
                @click="toggleArchived(false)"
            >
                {{ $t('services.current') }}
            </button>
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                :class="{ active: archived == true }"
                @click="toggleArchived(true)"
            >
                {{ $t('services.archived') }}
            </button>
        </div>

        <div class="search-container">
            <input v-model="searchQuery" class="search-input" :placeholder="$t('services.searchPlaceholder')" />
        </div>

        <div v-if="services.length === 0" class="no-services-message">
            <p>{{ $t('services.no_services') }}</p>
        </div>

        <div v-else class="services-list">
            <div class="list-header">
                <span>{{ $t('services.company') }}</span>
                <span>{{ $t('services.type') }}</span>
                <span>{{ $t('services.price') }}</span>
                <span>{{ $t('services.actions') }}</span>
            </div>
            <ul>
                <li v-for="service in services" :key="service.attributes.id" class="list-item">
                    <span>{{ service.attributes.customer_company_name }}</span>
                    <span>{{ $t(`company_services.types.${service.attributes.type}`) }}</span>
                    <span>{{ service.attributes.amount_format }}</span>
                    <button
                        class="view-details-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="$router.push({ path: `/company/services/view/${service.id}` })"
                    >
                        <i class="at-btn__icon icon icon-eye"></i>
                        {{ $t('control.view') }}
                    </button>
                </li>
            </ul>
        </div>

        <DeleteModal
            :visible="isModalVisible"
            :message="$t('services.delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />

        <div v-if="services.length > 0" class="pagination">
            <at-pagination
                :total="totalItems"
                :current="page"
                :page-size="itemsPerPage"
                class="crud__pagination"
                @page-change="onPageChange"
            >
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import CompanyServicesService from '../services/company-services.service';
    import DeleteModal from '@/components/DeleteModal.vue';
    import { mapGetters } from 'vuex';
    import debounce from 'lodash/debounce';

    export default {
        components: {
            DeleteModal,
        },
        data() {
            return {
                services: [],
                searchQuery: '',
                companyService: new CompanyServicesService(),
                isModalVisible: false,
                serviceToDelete: null,
                viewType: 'ordered',
                archived: false,

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,

                queryParams: {
                    page: this.$route.query.page,
                },
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        async created() {
            this.fetchServices();
        },
        methods: {
            toggleServiceScope(type) {
                this.viewType = type;
                this.fetchServices();
            },
            toggleArchived(value) {
                this.archived = value;
                this.fetchServices();
            },
            async fetchServices() {
                const params = {
                    ...(this.viewType === 'ordered' && { client_company_id: this.company.id }),
                    ...(this.searchQuery && { search_query: this.searchQuery }),
                    page: this.page,
                    archived: this.archived,
                };
                try {
                    const res = await this.companyService.getAll(params);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.services = data.map(item => ({
                        ...item,
                        attributes: {
                            ...item.attributes,
                            params: JSON.parse(item.attributes.params),
                        },
                    }));
                } catch (error) {
                    console.log('Error fetching services', error);
                }
            },
            getServiceTitle(service) {
                return `Service Type: ${this.$t(`company_services.types.${service.attributes.type}`)}`;
            },
            getDescription(service) {
                return service.attributes.params.description || 'No description available';
            },
            openDeleteModal(service) {
                this.serviceToDelete = service;
                this.isModalVisible = true;
            },
            closeDeleteModal() {
                this.isModalVisible = false;
                this.serviceToDelete = null;
            },
            async confirmDelete() {
                try {
                    await this.companyService.remove(this.serviceToDelete.attributes.id);
                    this.services = this.services.filter(s => s.attributes.id !== this.serviceToDelete.attributes.id);
                } catch (error) {
                    console.log('Error deleting service', error);
                } finally {
                    this.closeDeleteModal();
                }
            },

            async onPageChange(page) {
                await this.handlePageChange(page);
            },

            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchCompanyOrderedServices();
            },
        },
        watch: {
            viewType() {
                this.fetchServices();
            },

            searchQuery: debounce(function () {
                this.fetchServices();
            }, 350),
        },
    };
</script>

<style scoped lang="scss">
    .services-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .list-header,
        .list-item {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr;
            gap: 10px;
            align-items: center;
            padding: 12px 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background-color: #f9f9f9;

            .theme-dark & {
                background-color: #444;
                color: #ffa500;
                border-color: #333;
            }
        }

        .list-header {
            font-weight: bold;
            background-color: #f4f4f4;
            border: none;

            .theme-dark & {
                background-color: #555;
            }
        }

        .list-item {
            margin: 5px 0 5px 0;
            transition: background-color 0.3s ease;
        }

        .list-item:hover {
            background-color: #f1f1f1;
            cursor: pointer;

            .theme-dark & {
                background-color: #555;
            }
        }
    }

    .pagination {
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
    }

    .buttons {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .active {
        color: #ffa500;

        .theme-dark & {
            color: #c4c4cf;
            border-color: #c4c4cf;
        }
    }

    .no-services-message {
        font-size: 1.2em;
        color: #777;
        padding: 20px;
    }

    .search-input {
        width: 99%;
        margin-bottom: 15px;
        padding: 12px 16px;
        font-size: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }
</style>
