import CompanyDocumentsService from '../../document/services/document.service';
import CompanyDocuments from '../views/CompanyDocuments.vue';
import CurrentCompanyDocuments from '../components/CurrentCompanyDocuments.vue';
import ApprovedDocuments from '../components/ApprovedDocuments.vue';
import PendingDocuments from '../components/PendingDocuments.vue';

export default (context, router) => {
    return {
        accessCheck: async () => true,
        scope: 'company',
        order: 27,
        component: CompanyDocuments,
        route: {
            name: 'company.documents.crud.name',
            path: '/company/documents',
            meta: {
                label: 'company.documents.grid-title',
                service: new CompanyDocumentsService(),
            },

            children: [
                {
                    name: 'company.documents.current-company',
                    path: 'current-company',
                    service: new CompanyDocumentsService(),
                    component: CurrentCompanyDocuments,
                },
                {
                    name: 'company.documents.approved',
                    path: 'approved',
                    service: new CompanyDocumentsService(),
                    component: ApprovedDocuments,
                },
                {
                    name: 'company.documents.pending',
                    path: 'pending',
                    service: new CompanyDocumentsService(),
                    component: PendingDocuments,
                },
                {
                    name: 'company.documents.view',
                    path: 'view/:id',
                    service: new CompanyDocumentsService(),
                    component: () => import('../views/companyDocument/View.vue'),
                },
                {
                    name: 'company.documents.new',
                    path: 'new',
                    service: new CompanyDocumentsService(),
                    component: () => import('../views/companyDocument/New.vue'),
                },
                {
                    name: 'company.documents.approve',
                    path: 'approve/:id',
                    service: new CompanyDocumentsService(),
                    component: () => import('../views/companyDocument/Approve.vue'),
                },
            ],
        },
    };
};
