<template>
    <div>
        <h1>{{ $t('company.documents.main.title') }}</h1>
        <div v-if="!isActive('/company/documents/new')" class="header-buttons">
            <div class="navigation-buttons">
                <button
                    :class="[
                        'at-btn at-btn--primary at-btn--large action-button',
                        { active: isActive('/company/documents/current-company') },
                    ]"
                    @click="navigateTo('/company/documents/current-company')"
                >
                    {{ $t('company.documents.main.buttons.company_documents') }}
                </button>
                <button
                    :class="[
                        'at-btn at-btn--primary at-btn--large action-button',
                        { active: isActive('/company/documents/approved') },
                    ]"
                    @click="navigateTo('/company/documents/approved')"
                >
                    {{ $t('company.documents.main.buttons.approved_documents') }}
                </button>
                <button
                    :class="[
                        'at-btn at-btn--primary at-btn--large action-button',
                        { active: isActive('/company/documents/pending') },
                    ]"
                    @click="navigateTo('/company/documents/pending')"
                >
                    {{ $t('company.documents.main.buttons.pending_documents') }}
                </button>
            </div>
            <div class="create-button">
                <button
                    :class="[
                        'at-btn at-btn--primary at-btn--large action-button',
                        { active: isActive('/company/documents/new') },
                    ]"
                    @click="navigateTo('/company/documents/new')"
                >
                    {{ $t('company.documents.main.buttons.add_document') }}
                </button>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'company.documents',

        methods: {
            navigateTo(path) {
                this.$router.push(path).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            },
            isActive(path) {
                return this.$route.path === path;
            },
        },

        beforeRouteEnter(to, from, next) {
            if (to.path === '/company/documents/' || to.path === '/company/documents') {
                next('/company/documents/current-company');
            } else {
                next();
            }
        },

        beforeRouteUpdate(to, from, next) {
            if (to.path === '/company/documents/' || to.path === '/company/documents') {
                this.$router.replace('/company/documents/current-company').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                next();
            }
        },
    };
</script>

<style scoped lang="scss">
    .navigation-buttons {
        display: flex;
        gap: 10px;
        margin: 0 0 0 15px;
    }

    .header-buttons {
        display: flex;
        justify-content: space-between;
    }

    .create-button {
        margin-right: 20px;
    }

    h1 {
        margin-left: 15px;
    }

    .active {
        color: #ffa500;
        border: 2px solid #ffa500;
        background-color: #fff;

        .theme-dark & {
            color: #c4c4cf;
            border-color: #c4c4cf;
            background-color: #333;
        }
    }
</style>
