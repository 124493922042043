import RolePolicy from '../../policies/role.policy';

export const ModuleConfig = {
    routerPrefix: 'ClientUsers',
    loadOrder: 23,
    moduleName: 'ClientUsers',
};

export const ROLES_ACCESS = ['admin', 'manager'];

export default {
    ROLES_ACCESS: ROLES_ACCESS,
};

export function init(context) {
    context.addRoute({
        path: '/client-users',
        name: 'client-users',
        component: () => import(/* webpackChunkName: "invoice" */ './views/ClientUsers.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: 'list/:id',
                name: 'client.users.list',
                component: () => import(/* webpackChunkName: "invoice" */ './views/actions/List.vue'),
                meta: {
                    auth: true,
                    checkPermission: () => RolePolicy.haveRole(ROLES_ACCESS),
                },
            },
        ],
    });

    context.addLocalizationData({
        en: require('./locales/en'),
        ru: require('./locales/ru'),
        ua: require('./locales/ua'),
        pl: require('./locales/pl'),
        de: require('./locales/de'),
        fr: require('./locales/fr'),
    });

    return context;
}
