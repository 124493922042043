import axios from 'axios';

export default class ClientService {
    async getWithFilters(filters) {
        return await axios.post('api/v1/clients/list', filters);
    }

    async getItem(id, filters) {
        return await axios.get(`api/v1/clients/${id}`);
    }

    async save(data) {
        return await axios.post('api/v1/clients', { client: data });
    }

    async update(data, id) {
        return await axios.put(`api/v1/clients/${id}`, data);
    }

    async deleteItem(id) {
        return await axios.delete(`api/v1/clients/${id}`);
    }

    async autoComplete(params) {
        return await axios.post('api/v1/company-autocomplete', params);
    }
}
