import moment from 'moment-timezone';
import TasksService from '@/services/resource/task.service';
import UserService from '@/services/resource/user.service';
import DashboardService from '_internal/Dashboard/services/dashboard.service';
import _ from 'lodash';

const state = {
    service: null,
    intervals: {},
    tasks: {},
    users: [],
    timezone: moment.tz.guess(),
    theme: localStorage.getItem('theme') || 'system',
};

const getters = {
    service: state => state.service,
    intervals: state => state.intervals,
    tasks: state => state.tasks,
    users: state => state.users,
    theme: state => state.theme,
    timePerProject: (state, getters) => {
        return Object.keys(getters.intervals).reduce((result, index) => {
            const currentInterval = getters.intervals[index];
            const userEvents = getters.intervals.filter(interval => {
                return interval.attributes.user_id === currentInterval.attributes.user_id;
            });
            if (!userEvents) {
                return result;
            }

            const projects = userEvents.reduce((projects, event) => {
                if (!projects[event.attributes.project_id]) {
                    projects[event.attributes.project_id] = {
                        id: event.attributes.project_id,
                        name: event.attributes.project_name,
                        duration: event.attributes.duration,
                        tasks: {},
                        durationAtSelectedPeriod: event.attributes.durationAtSelectedPeriod,
                    };
                } else {
                    projects[event.attributes.project_id].duration += event.attributes.duration;
                    projects[event.attributes.project_id].durationAtSelectedPeriod += event.durationAtSelectedPeriod;
                }

                if (!projects[event.attributes.project_id].tasks[event.attributes.task_id]) {
                    projects[event.attributes.project_id].tasks[event.attributes.task_id] = {
                        id: event.attributes.task_id,
                        name: event.attributes.task_name,
                        duration: event.attributes.duration,
                        durationAtSelectedPeriod: event.durationAtSelectedPeriod,
                    };
                } else {
                    projects[event.attributes.project_id].tasks[event.attributes.task_id].duration +=
                        event.attributes.duration;
                    projects[event.attributes.project_id].tasks[event.attributes.task_id].durationAtSelectedPeriod +=
                        event.durationAtSelectedPeriod;
                }

                return projects;
            }, {});
            return {
                ...result,
                [currentInterval.attributes.user_id]: projects,
            };
        }, {});
    },
    timePerDay: (state, getters) => {
        return Object.keys(getters.intervals).reduce((result, index) => {
            const currentInterval = getters.intervals[index];
            const userEvents = getters.intervals.filter(interval => {
                return interval.attributes.user_id === currentInterval.attributes.user_id;
            });
            if (!userEvents) {
                return result;
            }

            const userTimePerDay = userEvents.reduce((result, event) => {
                const date = new Date(event.attributes.start_at).toLocaleDateString();
                const duration = event.attributes.duration;

                if (!result[date]) {
                    result[date] = 0;
                }

                result[date] += duration;

                return result;
            }, {});

            return {
                ...result,
                [currentInterval.attributes.user_id]: userTimePerDay,
            };
        }, {});
    },
    timezone: state => state.timezone,
};

const mutations = {
    setService(state, service) {
        state.service = service;
    },
    setIntervals(state, intervals) {
        state.intervals = intervals;
    },
    setTasks(state, tasks) {
        state.tasks = tasks;
    },
    setUsers(state, users) {
        state.users = users;
    },
    setTimezone(state, timezone) {
        state.timezone = timezone;
    },
    setTheme(state, theme) {
        state.theme = theme;
        localStorage.setItem('theme', theme);
    },
};

const actions = {
    init(context) {
        context.commit('setService', new DashboardService(context, new TasksService(), new UserService()));
    },
    updateTheme({ commit }, theme) {
        commit('setTheme', theme);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
