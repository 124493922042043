//dashboard.service.js
import axios from 'axios';
import ReportService from '@/services/report.service';
import moment from 'moment';

class DashboardService extends ReportService {
    constructor(context, taskService, userService) {
        super();
        this.context = context;

        this.taskService = taskService;
        this.userService = userService;
    }

    async fetchProjects() {
        try {
            const responseProject = await axios.post('api/v1/project/list', { disable_pagy: true });
            const responseTask = await axios.post('api/v1/task/list', { disable_pagy: true });
            const projects = responseProject.data.data;
            const tasks = responseTask.data.data;

            return tasks
                .filter(task => task.attributes.project_id)
                .map(task => {
                    const project = projects.find(project => project.id === task.attributes.project_id);
                    return {
                        type: 'task',
                        ...task,
                        attributes: {
                            ...task.attributes,
                            projectName: project ? project.attributes.name : null,
                            color: '#824C00',
                            textColor: '#824C00',
                        },
                    };
                });
        } catch (error) {
            console.error('Failed to fetch projects:', error);
            throw error; // Throw the error so the calling code can handle it
        }
    }
    downloadReport(startAt, endAt, users, projects, userTimezone, format, sortCol, sortDir) {
        return axios.post(
            'api/v1/report/dashboard/download',
            {
                start_at: startAt,
                end_at: endAt,
                users,
                projects,
                user_timezone: userTimezone,
                sort_column: sortCol,
                sort_direction: sortDir,
                format: format,
            },
            {
                headers: { Accept: format },
            },
        );
    }

    getReport(startAt, endAt, users, projects, userTimezone) {
        return axios.post('api/v1/report/dashboard', {
            users,
            projects,
            start_at: startAt,
            end_at: endAt,
            user_timezone: userTimezone,
        });
    }

    unloadIntervals() {
        this.context.commit('setIntervals', []);
    }

    load(userIDs, projectIDs, startAt, endAt, userTimezone) {
        this.getReport(startAt, endAt, userIDs, projectIDs, userTimezone)
            .then(response => {
                if (!response) {
                    return;
                }

                const { data } = response.data;

                this.context.commit('setIntervals', data);

                if (!data) {
                    return;
                }

                const uniqueProjectIDs = new Set();
                const uniqueTaskIDs = new Set();
                data.forEach(interval => {
                    uniqueProjectIDs.add(interval.attributes.project_id);
                    uniqueTaskIDs.add(interval.attributes.task_id);
                });

                const promises = [];

                const taskIDs = [...uniqueTaskIDs];
                if (taskIDs.length) {
                    promises.push(this.loadTasks(taskIDs));
                }

                return Promise.all(promises);
            })
            .then(() => {
                return this.loadUsers();
            })
            .then(() =>
                this.context.commit(
                    'setUsers',
                    this.context.state.users.map(u => {
                        if (this.context.state.intervals.hasOwnProperty(u.attributes.id)) {
                            const userIntervals = this.context.state.intervals.filter(interval => {
                                return interval.attributes.user_id == u.attributes.id;
                            });

                            const lastInterval = userIntervals.slice(-1)[0];

                            if (!lastInterval) {
                                return;
                            }

                            if (
                                Math.abs(
                                    moment(lastInterval.attributes.end_at).diff(
                                        moment().subtract(u.attributes.screenshot_interval || 1, 'minutes'),
                                        'seconds',
                                    ),
                                ) < 10
                            ) {
                                return {
                                    ...u,
                                    last_interval: lastInterval,
                                };
                            }
                        }

                        return { ...u, last_interval: null };
                    }),
                ),
            )
            .catch(e => {
                if (!axios.isCancel(e)) {
                    console.log('error');
                    throw e;
                }
            });
    }

    loadTimes(params) {
        return axios.post('/api/v1/time-intervals/list/timeline', params);
    }

    loadUsers() {
        return this.userService
            .getAll({ headers: { 'X-Paginate': false } })
            .then(response => {
                this.context.commit('setUsers', response.data.data);
                return response.data.data;
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    throw e;
                }
            });
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param taskIDs
     * @param action
     */
    loadTasks(taskIDs) {
        return this.taskService
            .getWithFilters({
                id: ['=', taskIDs],
                with: 'project',
            })
            .then(response => {
                if (typeof response !== 'undefined') {
                    const { data } = response.data;
                    const tasks = data.reduce((tasks, task) => {
                        tasks[task.attributes.id] = task.attributes;
                        return tasks;
                    }, {});

                    this.context.commit('setTasks', tasks);
                    return tasks;
                }
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    throw e;
                }
            });
    }

    sendInvites(emails) {
        return axios.post(`register/create`, emails);
    }
}

export default DashboardService;
