<template>
    <div class="auth-layout">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'auth-layout',
    };
</script>

<style scoped></style>
