<!--default-layout-->
<template>
    <div class="default-layout">
        <div class="drawer-overlay-mobile" @click="closeMobileMenu"></div>
        <AsideLeft v-if="isLoggedIn" :is-mobile-menu-open="isMobileMenuOpen" :modules="modules" />
        <main>
            <Navigation v-if="isLoggedIn" :modules="modules" @update:is-mobile-menu-open="isMobileMenuOpen = $event" />
            <div class="content-wrapper">
                <div class="container-fluid">
                    <slot></slot>
                </div>
            </div>
        </main>

        <!-- /.content-wrapper -->
    </div>
</template>

<script>
    import Navigation from '@/components/Navigation';
    import AsideLeft from '@/components/AsideLeft';
    import { getModuleList } from '@/moduleLoader';
    import Store from '@/store';

    export default {
        name: 'default-layout',
        components: {
            AsideLeft,
            Navigation,
        },
        data() {
            return {
                isMobileMenuOpen: false,
                modules: this.getModulesSomehow(),
            };
        },
        computed: {
            isLoggedIn() {
                return Store.getters['user/loggedIn'];
            },
        },
        methods: {
            getModulesSomehow() {
                return Object.values(getModuleList()).map(i => i.moduleInstance);
            },
            closeMobileMenu() {
                this.isMobileMenuOpen = false;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .drawer-overlay-mobile {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: 109;
        background-color: rgba(0, 0, 0, 0.2);
        animation: animation-drawer-fade-in 0.3s ease-in-out 1;
    }
    @media (max-width: 992px) {
        .drawer-overlay-mobile.--active-overlay {
            display: block;
        }
    }
</style>
