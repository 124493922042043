var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('company.documents.main.title')))]),(!_vm.isActive('/company/documents/new'))?_c('div',{staticClass:"header-buttons"},[_c('div',{staticClass:"navigation-buttons"},[_c('button',{class:[
                    'at-btn at-btn--primary at-btn--large action-button',
                    { active: _vm.isActive('/company/documents/current-company') },
                ],on:{"click":function($event){return _vm.navigateTo('/company/documents/current-company')}}},[_vm._v(" "+_vm._s(_vm.$t('company.documents.main.buttons.company_documents'))+" ")]),_c('button',{class:[
                    'at-btn at-btn--primary at-btn--large action-button',
                    { active: _vm.isActive('/company/documents/approved') },
                ],on:{"click":function($event){return _vm.navigateTo('/company/documents/approved')}}},[_vm._v(" "+_vm._s(_vm.$t('company.documents.main.buttons.approved_documents'))+" ")]),_c('button',{class:[
                    'at-btn at-btn--primary at-btn--large action-button',
                    { active: _vm.isActive('/company/documents/pending') },
                ],on:{"click":function($event){return _vm.navigateTo('/company/documents/pending')}}},[_vm._v(" "+_vm._s(_vm.$t('company.documents.main.buttons.pending_documents'))+" ")])]),_c('div',{staticClass:"create-button"},[_c('button',{class:[
                    'at-btn at-btn--primary at-btn--large action-button',
                    { active: _vm.isActive('/company/documents/new') },
                ],on:{"click":function($event){return _vm.navigateTo('/company/documents/new')}}},[_vm._v(" "+_vm._s(_vm.$t('company.documents.main.buttons.add_document'))+" ")])])]):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }